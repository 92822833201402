.dropDown {
    position: absolute;
    top: 4.5rem;
    right: 1.5rem;
    width: 120px;
    padding: 15px;
    border-radius: 15px;
    background-color: white;
    border: 1px solid gray;
}

.dropDown::before{
    content: '';
    position: absolute;
    top: -0.7rem;
    right: 1.1rem;
    width: 20px;
    height: 20px;
    transform: rotate(45deg);
    background-color: white;
    border-left: 1px solid gray;
    border-bottom: 1px solid gray;
}
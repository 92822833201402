.split {
    height: screen;
    width: 70%;
    position: fixed;
    z-index: 1;
    top: 0;
    overflow-x: hidden;
    overflow-y: hidden;

  }
  
  /* Control the left side */
  .left {
    left: 0;
    /* width: 200px; */
  }
  
  /* Control the right side */
  .right {
    right: 0;
  }

  .overflow {
    overflow: hidden;

  }
/*   
  body {
    overflow: hidden; 
  } */

  body::-webkit-scrollbar {
    display: none; 
  }